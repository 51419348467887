import { LaptopOutlined } from '@ant-design/icons';
import { Card, Collapse, Table, Input as InputAnt, Checkbox } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { z } from 'zod';
import get from 'lodash.get';
import * as API from '../../api';
import { DictFormActions, DictTableActions, DictTableStatus, Input, InputNumber, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useWindowSize } from '../../hooks';
import { AKTIF_PASIF, ROL, URUN_GRUP, UrunModel } from '../../types';
import { booleanRender, moneyColumnRender, numberFormat, yesNoRender } from '../../utils';

const compNum = (keys: string[] | keyof UrunModel) => (a: UrunModel, b: UrunModel) => {
  const aKey = Number(get(a, keys));
  const bKey = Number(get(b, keys));

  return aKey - bKey;
};

const grupMap: Record<string, string> = {
  INDIRIMSIZ: 'İndirimsiz',
  KUPON300: 'Kupon 500 TL',
  YUZDE15: 'Yüzde 15',
  YUZDE20: 'Yüzde 20',
};

const grupOptions = [
  {
    value: URUN_GRUP.INDIRIMSIZ,
    label: 'İndirimsiz',
  },
  {
    value: URUN_GRUP.KUPON300,
    label: 'Kupon 500 TL',
  },
  {
    value: URUN_GRUP.YUZDE15,
    label: 'Yüzde 15',
  },
  {
    value: URUN_GRUP.YUZDE20,
    label: 'Yüzde 20',
  },
];

const ProductForm = z.object({
  urunAdi: z.string().min(1, 'Zorunludur'),
  barkodNo: z.string().nullable().optional(),
  alisFiyat: z.number().min(0, 'Zorunludur'),
  satisFiyat: z.number().min(0, 'Zorunludur'),
  hedef: z.number().min(0, 'Zorunludur'),
  urunGrup: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),

  hediyeUrunId: z.number().min(0, 'Zorunludur').nullish(),
  hediyeAlisFiyat: z.number().min(0, 'Zorunludur').nullish(),
  maxAlisAdet: z.number().min(0, 'Zorunludur').nullish(),
});

export const Product = () => {
  const { sm } = useWindowSize();
  const { firmaModel } = useContext(UserContext);

  const [oncelikliMi, setOncelikliMi] = useState(false);

  const [list = [], loading, getList] = API.PRODUCT.useFetch({ init: true, initBody: firmaModel });
  const active = API.PRODUCT.useFetchActive({});
  const { form, selected, clear, fill } = useFillForm(ProductForm, {
    urunAdi: '',
    barkodNo: '',
    alisFiyat: 0,
    satisFiyat: 0,
    hedef: 0,
    urunGrup: URUN_GRUP.KUPON300,
    hediyeUrunId: undefined,
    hediyeAlisFiyat: 0,

    maxAlisAdet: 0,
  });

  const [search, setSearch] = useState<string>('');

  const save = useCallback(async () => {
    const { success, data } = form.parse();

    if (success) {
      const formedData = {
        urunAdi: data.urunAdi,
        barkodNo: data.barkodNo,
        alisFiyat: data.alisFiyat,
        satisFiyat: data.satisFiyat,
        hedef: data.hedef,
        urunGrup: data.urunGrup,
        bhrKategoriModel: {
          kategoriId: 81,
        },
        bhrMarkaModel: {
          markaId: 301,
        },

        hediyeUrunId: data.hediyeUrunId,
        hediyeAlisFiyat: data.hediyeAlisFiyat,
        maxAlisAdet: data.maxAlisAdet,

        oncelikliMi: oncelikliMi || false,
      };

      if (selected) {
        await API.PRODUCT.update({
          ...formedData,
          aktifPasif: selected.aktifPasif,
          urunId: selected.urunId,
          firmaModel,
        });
      } else {
        await API.PRODUCT.save({
          ...formedData,
          firmaModel,
        });
      }

      clear();
      setOncelikliMi(false);
      getList(firmaModel);
      active[2](firmaModel);
    } else {
      form.setAllTouched();
    }
  }, [form, selected, oncelikliMi]);

  const remove = useCallback(async (values) => {
    await API.PRODUCT.activePassive({
      urunId: values.urunId,
      aktifPasif: values.aktifPasif === AKTIF_PASIF.AKTIF ? AKTIF_PASIF.PASIF : AKTIF_PASIF.AKTIF,
    });

    clear();
    setOncelikliMi(false);
    getList(firmaModel);
  }, []);

  const fillForm = useCallback(
    (values) => {
      fill({
        urunId: values.urunId,
        urunAdi: values.urunAdi,
        barkodNo: values.barkodNo,
        aktifPasif: values.aktifPasif,
        alisFiyat: values.alisFiyat,
        satisFiyat: values.satisFiyat,
        hedef: values.hedef,
        urunGrup: values.urunGrup,

        hediyeUrunId: values.hediyeUrunId,
        hediyeAlisFiyat: values.hediyeAlisFiyat,
        maxAlisAdet: values.maxAlisAdet,
      });

      setOncelikliMi(values.oncelikliMi)
    },
    [fill, setOncelikliMi]
  );

  const filteredList = useMemo(() => {
    return list.filter(
      (item) =>
        item.urunAdi?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.bhrMarkaModel?.markaAdi?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.bhrKategoriModel?.kategoriAdi?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  }, [list, search]);

  const actives = useMemo(() => {
    return list.filter((item) => item.aktifPasif === AKTIF_PASIF.AKTIF);
  }, [list]);

  const columns = [
    {
      title: 'Ürün Adı',
      dataIndex: 'urunAdi',
      key: 'urunAdi',
    },
    {
      title: 'Barkod',
      dataIndex: 'barkodNo',
      key: 'barkodNo',
    },
    {
      title: 'Alış Fiyat',
      dataIndex: 'alisFiyat',
      key: 'alisFiyat',
      render: moneyColumnRender,
    },
    {
      title: 'Satış Fiyat',
      dataIndex: 'satisFiyat',
      key: 'satisFiyat',
      render: moneyColumnRender,
    },
    {
      title: 'Hedef',
      dataIndex: 'hedef',
      key: 'hedef',
      render: numberFormat,
      sorter: compNum('hedef'),
    },
    {
      title: 'Hediye Ürün',
      dataIndex: 'hediyeUrunId',
      key: 'hediyeUrunId',
    },
    {
      title: 'Hediye Alış Fiyat',
      dataIndex: 'hediyeAlisFiyat',
      key: 'hediyeAlisFiyat',
      render: moneyColumnRender,
    },
    {
      title: 'Max Alış Adet',
      dataIndex: 'maxAlisAdet',
      key: 'maxAlisAdet',
      render: numberFormat,
    },
    {
      title: 'Grup',
      dataIndex: 'urunGrup',
      key: 'urunGrup',
      render: (_: any, record: UrunModel) => (record.urunGrup ? grupMap[record.urunGrup] : ''),
    },
    {
      title: 'Öncelikli Mi',
      dataIndex: 'oncelikliMi',
      key: 'oncelikliMi',
      width: sm ? 100 : 70,
      render: booleanRender,
    },
    {
      title: 'Durum',
      dataIndex: 'aktifPasif',
      key: 'aktifPasif',
      width: sm ? 100 : 70,
      render: (_: any, record: UrunModel) => <DictTableStatus record={record} />,
    },
    {
      title: 'İşlemler',
      key: 'actions',
      width: sm ? 240 : 120,
      render: (_: any, record: UrunModel) => <DictTableActions record={record} remove={remove} fill={fillForm} />,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <Collapse accordion defaultActiveKey="1">
        <Collapse.Panel header={<span className="text-base font-semibold ">Yeni Ürün Tanımlama</span>} key="1">
          <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:grid sm:grid-cols-[100px_1fr_100px_1fr_100px_1fr_100px_1fr] sm:grid-flow-row">
            <label className="whitespace-nowrap">Ürün Adı :</label>
            <Input form={form} name="urunAdi" />

            <label className="whitespace-nowrap">Barkod :</label>
            <Input form={form} name="barkodNo" />

            <label className="whitespace-nowrap">Alış Fiyat :</label>
            <InputNumber form={form} name="alisFiyat" min={0} />

            <label className="whitespace-nowrap">Satış Fiyat :</label>
            <InputNumber form={form} name="satisFiyat" min={0} />

            <label className="whitespace-nowrap">Hedef :</label>
            <InputNumber form={form} name="hedef" min={0} />

            <label className="whitespace-nowrap">Grup :</label>
            <Select options={grupOptions} fieldNames={{ label: 'label', value: 'value' }} form={form} name="urunGrup" />

            <label className="whitespace-nowrap">Hediye Ürün :</label>
            <Select
              options={actives}
              fieldNames={{ label: 'urunAdi', value: 'urunId' }}
              searchFields={['barkodNo']}
              placeholder="Ürün Adı veya Barkod"
              form={form}
              name="hediyeUrunId"
            />

            <label className="whitespace-nowrap">Hediye Alış Fiyat :</label>
            <InputNumber form={form} name="hediyeAlisFiyat" min={0} />

            <label className="whitespace-nowrap">Max Alış Adet :</label>
            <InputNumber form={form} name="maxAlisAdet" min={0} />

            <div className="col-span-1"></div>
            <Checkbox className="col-span-2" checked={oncelikliMi} onChange={(e: any) => setOncelikliMi(e.target.checked)}>
              Öncelikli Mi
            </Checkbox>
            <div className="col-span-2"></div>

            <DictFormActions selected={selected} save={save} clear={() => {
              clear();
              setOncelikliMi(false);
            }} className="sm:col-span-4 sm:justify-end" />
          </div>
        </Collapse.Panel>
      </Collapse>

      <div className="flex items-center gap-2 ml-4">
        <label>Ara :</label>
        <InputAnt.Search className="w-64" placeholder="Ürün, Marka veya Kategori..." allowClear onChange={(e) => setSearch(e.target.value)} />
      </div>

      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100svh - 190px)' }}
        pagination={false}
        rowKey="urunId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={filteredList}
        loading={loading}
      />
    </div>
  );
};

Product.path = 'product';
Product.title = 'Ürün';
Product.group = 'dicts';
Product.roles = [ROL.ADMIN];
Product.icon = <LaptopOutlined />;
