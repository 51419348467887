import { LaptopOutlined, SettingOutlined } from '@ant-design/icons';
import { Card, Collapse, Table, Input as InputAnt, Checkbox } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import get from 'lodash.get';
import * as API from '../../api';
import { DictFormActions, DictTableActions, DictTableStatus, Input, InputNumber, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useWindowSize } from '../../hooks';
import { AKTIF_PASIF, ROL, URUN_GRUP, UrunModel } from '../../types';
import { booleanRender, moneyColumnRender, numberFormat, yesNoRender } from '../../utils';

const compNum = (keys: string[] | keyof UrunModel) => (a: UrunModel, b: UrunModel) => {
  const aKey = Number(get(a, keys));
  const bKey = Number(get(b, keys));

  return aKey - bKey;
};

const grupMap: Record<string, string> = {
  INDIRIMSIZ: 'İndirimsiz',
  KUPON300: 'Kupon 500 TL',
  YUZDE15: 'Yüzde 15',
  YUZDE20: 'Yüzde 20',
};

const grupOptions = [
  {
    value: URUN_GRUP.INDIRIMSIZ,
    label: 'İndirimsiz',
  },
  {
    value: URUN_GRUP.KUPON300,
    label: 'Kupon 500 TL',
  },
  {
    value: URUN_GRUP.YUZDE15,
    label: 'Yüzde 15',
  },
  {
    value: URUN_GRUP.YUZDE20,
    label: 'Yüzde 20',
  },
];

const ProductForm = z.object({
  indirimsizAltSinir: z.number().min(0, 'Zorunludur'),
});

export const Config = () => {
  const { sm } = useWindowSize();
  const { firmaModel } = useContext(UserContext);

  const [config, loading, getConfig] = API.CONFIG.useFetch({ init: true, initBody: firmaModel });

  const { form, selected, clear, fill } = useFillForm(ProductForm, {
    indirimsizAltSinir: 0,
  });

  const save = useCallback(async () => {
    const { success, data } = form.parse();

    if (success) {
      const formedData = {
        indirimsizAltSinir: data.indirimsizAltSinir,
      };

      if (config?.configId) {
        await API.CONFIG.update({
          ...formedData,
          configId: config.configId,
          firmaModel,
        });
      }

      clear();
      getConfig(firmaModel);
    } else {
      form.setAllTouched();
    }
  }, [form, config]);

  useEffect(() => {
    if (config) {
      fill({
        indirimsizAltSinir: config.indirimsizAltSinir,
      });
    }
  }, [config]);

  return (
    <div className="flex flex-col gap-2">
      <Collapse accordion defaultActiveKey="1">
        <Collapse.Panel header={<span className="text-base font-semibold ">Yeni Ürün Tanımlama</span>} key="1">
          <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:max-w-3xl sm:grid sm:grid-cols-[120px_1fr_100px_1fr] sm:grid-flow-row">
            <label className="whitespace-nowrap">İndirimsiz Alt Sınır :</label>
            <InputNumber form={form} name="indirimsizAltSinir" min={0} />

            <span></span>
            <span></span>
            
            <DictFormActions selected={selected} save={save} clear={clear} className="sm:col-span-4 sm:justify-end" noClear/>
            
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

Config.path = 'config';
Config.title = 'Genel Ayar';
Config.group = 'dicts';
Config.roles = [ROL.ADMIN];
Config.icon = <SettingOutlined />;
